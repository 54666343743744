import store from 'store'
import React, { useState, useEffect, useContext, useCallback } from 'react'
import { EviApi } from '../../axios-api'
import { ApiEndpoint } from '../../settings/urls'
import { alertError } from '../../functions/alertMessage'

const COMPANY_STORE = 'evi:subsidiary'
const OPERATION_STORE = 'evi:operation'
const USER_STORE = 'evi:user'
const ECONOMIC_GROUP_STORE = 'evi:economicgroup'

interface Option {
  label: string
  value: string
}

interface ContextProps {
  selectOperation?: (v: string) => void
  selectCompany?: (v: string) => void
  operationHash?: string
  setOperationHash?: React.Dispatch<React.SetStateAction<string>>
  companyHash?: string
  setCompanyHash?: React.Dispatch<React.SetStateAction<string>>
  loadingCompanies?: boolean
  loadingOperations?: boolean
  operationOptions?: Option[]
  companyOptions?: Option[]
  selectedOperation?: Option
  selectedCompany?: Option
  getCompanyOperationOptions?: (h: string) => Option[]
}

const UserOperationContext = React.createContext<ContextProps>({})

export function UserOperationProvider ({ children }) {
  const [loadingCompanies, setLoadingCompanies] = useState(true)
  const [loadingOperations, setLoadingOperations] = useState(true)
  const [companyOptions, setCompanyOptions] = useState([])
  const [operationOptions, setOperationOptions] = useState([])

  const [selectedCompany, setSelectedCompany] = useState({
    label: '',
    value: window.sessionStorage.getItem(COMPANY_STORE) || store.get(COMPANY_STORE)
  })
  const [selectedOperation, setSelectedOperation] = useState({
    label: '',
    value: window.sessionStorage.getItem(OPERATION_STORE) || store.get(OPERATION_STORE)
  })
  const [operationHash, setOperationHash] = useState<string>(
    window.sessionStorage.getItem(OPERATION_STORE) || store.get(OPERATION_STORE)
  )
  const [companyHash, setCompanyHash] = useState<string>(
    window.sessionStorage.getItem(COMPANY_STORE) || store.get(COMPANY_STORE)
  )

  useEffect(() => {
    if (companyHash) {
      window.sessionStorage.setItem(COMPANY_STORE, companyHash)
      store.set(COMPANY_STORE, companyHash)
    } else {
      window.sessionStorage.setItem(COMPANY_STORE, '')
      store.set(COMPANY_STORE, '')
    }
  }, [companyHash])

  useEffect(() => {
    if (operationHash) {
      window.sessionStorage.setItem(OPERATION_STORE, operationHash)
      store.set(OPERATION_STORE, operationHash)
    } else {
      window.sessionStorage.setItem(OPERATION_STORE, '')
      store.set(OPERATION_STORE, '')
    }
  }, [operationHash])

  const [allUserOperations, setAllUserOperations] = useState([])
  const getCompanyOperationOptions = useCallback((companyHash: string) => {
    const operationOptions = []
    for (const op of allUserOperations) {
      const isUnique = !operationOptions.find((option) =>
        option.value === op.operation_hash && op.subsidiary_hash === companyHash)
      const isFromCompany = op.subsidiary_hash === companyHash
      if (isUnique && isFromCompany) {
        operationOptions.push({ label: op.operation_name, value: op.operation_hash })
      }
    }

    return operationOptions
  }, [allUserOperations])

  const loadOperationOptions = useCallback(async (sub) => {
    const userHash = store.get(USER_STORE)
    const economicGroupHash = store.get(ECONOMIC_GROUP_STORE)
    setLoadingOperations(true)
    setOperationOptions([])

    try {
      const { data } = await EviApi.get(`${ApiEndpoint.USER_OPERATION_COMPANY}/${economicGroupHash}/${userHash}`)

      setAllUserOperations(data.operations)
      const operations = []
      const operationOptions = []

      for (const op of data.operations) {
        if (op.subsidiary_hash === sub) {
          operations.push(op)
        }
      }

      for (const op of operations) {
        if (!operationOptions.find((option) =>
          option.value === op.operation_hash && op.subsidiary_hash === sub.value)) {
          operationOptions.push({ label: op.operation_name, value: op.operation_hash })
        }
      }
      setOperationOptions(operationOptions)
    } catch (err) {
      alertError(err)
    } finally {
      setLoadingOperations(false)
    }
  }, [])

  const selectCompany = useCallback((hash) => {
    const sub = companyOptions.find((v) => v.value === hash)

    setSelectedCompany(sub)
    setCompanyHash(hash)
    setOperationHash(null)
    setSelectedOperation({ label: '', value: null })
    loadOperationOptions(hash)
  }, [companyOptions, loadOperationOptions])

  const selectOperation = useCallback((hash) => {
    const ope = operationOptions.find((v) => v.value === hash)
    setSelectedOperation(ope)

    setOperationHash(hash)
  }, [operationOptions])

  const loadCompanyOptions = useCallback(async () => {
    setLoadingCompanies(true)
    setCompanyOptions([])
    setOperationOptions([])
    const user_hash = store.get(USER_STORE)
    const economic_group_hash = store.get(ECONOMIC_GROUP_STORE)

    try {
      const { data } = await EviApi.get(`${ApiEndpoint.USER_OPERATION_COMPANY}/${economic_group_hash}/${user_hash}`)
      const companyOptions = []
      for (const op of data.operations) {
        if (!companyOptions.find((option) => option.value === op.subsidiary_hash)) {
          companyOptions.push({ label: op.subsidiary_name, value: op.subsidiary_hash })
        }
      }
      setCompanyOptions(companyOptions)
    } catch (err) {
      alertError(err)
    } finally {
      setLoadingCompanies(false)
    }
  }, [])

  useEffect(() => {
    loadCompanyOptions()
    loadOperationOptions(window.sessionStorage.getItem(COMPANY_STORE) || store.get(COMPANY_STORE)
    )
  }, [loadCompanyOptions, loadOperationOptions])

  return (
    <UserOperationContext.Provider value={{
      operationHash,
      setOperationHash,
      companyHash,
      setCompanyHash,
      loadingCompanies,
      loadingOperations,
      selectCompany,
      selectOperation,
      selectedCompany,
      selectedOperation,
      companyOptions,
      operationOptions,
      getCompanyOperationOptions
    }}>
      {children}
    </UserOperationContext.Provider>
  )
}

export function useUserOperation () {
  return useContext(UserOperationContext)
}
