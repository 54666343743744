import React, { useContext, useState, useCallback } from 'react'
import { Layout, Spin, Col } from 'antd'
import TopbarUser from './topbarUser'
import TopbarWrapper from './topbar.style'
import themes from '../../../settings/themes'
import { themeConfig } from '../../../settings'
import styled from 'styled-components'
import { SidebarContext } from '../../../services/Sidebar'
import store from 'store'
import { useLocation } from 'react-router-dom'
import { TextField } from '../../../components/TextField'
import { useUserOperation } from '../../../components/hooks/useUserOperation'
import { Margin, StandardInputMargin } from '../../../components/Margin'
import { Flexbox } from '../../../components/Flexbox'
import { EviButton } from '../../../components/EviButton'

const USER_EMAIL_STORE = 'evi:useremail'
const LICENSE_PLATE_STORE = 'evi:licenseplate'

const customizedTheme = themes[themeConfig.theme]
const Header = styled(Layout.Header)`
  background: ${customizedTheme.backgroundColor};
  position: fixed;
  width: 100%;
  height: 70px
`

const licensePlateFilterPaths = [
  '/main/dashboard',
  '/main/indicadores',
  '/main/entregas'
]

export default function (props) {
  const sidebarCtx = useContext(SidebarContext)
  const collapsed = sidebarCtx.collapsed && !sidebarCtx.openDrawer
  const location = useLocation()
  const userEmail = store.get(USER_EMAIL_STORE)
  const {
    companyOptions,
    selectedCompany,
    operationOptions,
    selectedOperation,
    loadingCompanies
  } = useUserOperation()

  const [licensePlate, setLicensePlate] = useState(
    window.sessionStorage.getItem(LICENSE_PLATE_STORE) || store.get(LICENSE_PLATE_STORE)
  )

  const blurLicensePlate = useCallback((val) => {
    if (val) {
      window.sessionStorage.setItem(LICENSE_PLATE_STORE, val)
      store.set(LICENSE_PLATE_STORE, val)
    } else {
      window.sessionStorage.removeItem(LICENSE_PLATE_STORE)
      store.remove(LICENSE_PLATE_STORE)
    }
    if (location.pathname !== '/main/operations') {
      window.location.reload()
    }
  }, [location.pathname])

  return (
    <TopbarWrapper className={props.className}>
      <Header
        className={
          collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
        }
      >
        <div className='isoLeft'>
          <button
            className={
              collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
            }
            style={{ color: customizedTheme.textColor, paddingLeft: collapsed ? '10px' : '30px' }}
            onClick={sidebarCtx.toggleCollapsed}
          />
        </div>
        {loadingCompanies ? <Spin />
          : <div className='selectFieldsTopBar'>
            <Flexbox align='left' justify='flex-start'>
              <Col>
                <StandardInputMargin style={{ flexGrow: 1 }}>
                  <label style={{ flexGrow: 1 }}>
                    <Flexbox vertical align='left'>

                      <Margin bottom={2} style={{ color: '#573E74', marginTop: '-27px', marginBottom: '-22px' }}>
                        Filial
                      </Margin>

                      <p style={{ color: '#ffff', marginTop: '-19px' }}>
                        { companyOptions.find((com) => com.value === selectedCompany?.value)?.label}
                      </p>

                    </Flexbox>
                  </label>
                </StandardInputMargin>
              </Col>
              <Col>
                <StandardInputMargin style={{ flexGrow: 1 }}>
                  <label style={{ flexGrow: 1 }}>
                    <Flexbox vertical align='left'>

                      <Margin bottom={2} style={{ color: '#573E74', marginTop: '-27px', marginBottom: '-22px' }}>
                        Operação
                      </Margin>

                      <p style={{ color: '#ffff', marginTop: '-19px' }}>
                        { operationOptions.find((ope) => ope.value === selectedOperation?.value)?.label}
                      </p>

                    </Flexbox>
                  </label>
                </StandardInputMargin>
              </Col>
              {licensePlateFilterPaths.includes(location.pathname) && (
                <>
                  <TextField
                    span={ 4 }
                    placeholder='Placa do veículo'
                    labelMarginTop='-38px'
                    labelMarginBottom='-22px'
                    labelColor='#573E74'
                    id='vehicle_license_plate'
                    value={licensePlate}
                    onChange={setLicensePlate}
                    onPressEnter={() => blurLicensePlate(licensePlate)}
                    allowClear={true}
                  />
                  <EviButton
                    type='standard'
                    onClick={() => blurLicensePlate(licensePlate)}
                    id='submit-operation'
                  >
                    Filtrar
                  </EviButton>
                </>
              )}
              <div style={{ textAlign: 'end', marginLeft: 'auto' }}>
                <p style={{ color: '#ffff', marginTop: '-8px' }}>{userEmail}</p>
              </div>
            </Flexbox>
          </div>
        }
        <ul className='isoRight'>
          <li className='isoUser' >
            <TopbarUser />
          </li>
        </ul>
      </Header>
    </TopbarWrapper>
  )
}
