const siteConfig = {
  siteName: 'EVI',
  siteIcon: 'ion-ios-color-filter-outline',
  footerText: 'EVI @2022'
}
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
}

const googleConfig = {
  apiKey: '' //
}

const language = 'english'

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey'
}

export { siteConfig, language, themeConfig, jwtConfig, googleConfig }
