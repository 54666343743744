import axios from 'axios'
import store from 'store'
import { ApiUrl } from './settings/urls'

// instance.defaults.headers.common['Authorization'] = 'Basic Tm92b1VzZXI6MTIzNA==';

export const EviApi = axios.create({
  baseURL: ApiUrl.API
})

EviApi.defaults.headers.Authorization = store.get('evi:apiToken')

export const VehicleAPI = axios.create({
  baseURL: ApiUrl.VEHICLE
})

export const CompanyAPI = axios.create({
  baseURL: ApiUrl.COMPANY
})

export const DriverAPI = axios.create({
  baseURL: ApiUrl.DRIVER
})
