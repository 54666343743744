export type Validity = 'error' | 'success' | 'warning' | 'validating'

export interface Validation {
  [k: string]: Validity
}

export interface ValidatorSchema {
  [k: string]: {
    required?: boolean
    matches?: RegExp
    minValue?: number
  }
}

export const fieldIsOk = (value) => value == null || value === 'success'

export const FormSchemaValidator = (schema: ValidatorSchema) =>
  (object, validateAll = false): Validation => {
    const newFieldValidation = {}
    for (const [k, rules] of Object.entries(schema)) {
      if (object[k] == null && !validateAll) {
        continue
      }

      if (rules.required) {
        newFieldValidation[k] = object[k] ? 'success' : 'error'
      }

      if (rules.matches) {
        newFieldValidation[k] = String(object[k] || '').match(rules.matches) ? 'success' : 'error'
      }

      if (rules.minValue) {
        newFieldValidation[k] = Number(object[k] || 0) >= rules.minValue ? 'success' : 'error'
      }

      if (!newFieldValidation[k]) {
        newFieldValidation[k] = 'success'
      }
    }

    return newFieldValidation
  }
