import React, { KeyboardEvent, useCallback } from 'react'
import AntModal from '../theme/components/feedback/modal'

interface ModalProps {
  bind
  onOk?: () => void
  onCancel: () => void
  title: React.ReactNode
  children?
  width? : number | string
  closable?
}
export function Modal ({ bind, children, closable, width = 845, onCancel, ...rest }: ModalProps) {
  const checkKeydown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      onCancel()
    }
  }, [onCancel])

  if (!bind) {
    return <></>
  }

  return (
    <AntModal
      width={width}
      visible={!!bind}
      closable={closable}
      okButtonProps={{ style: { color: '#c0c0c0', cursor: 'not-allowed' } }}
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      {...rest}
    >
      <div onKeyDown={checkKeydown}>
        {children}
      </div>
    </AntModal>
  )
}
