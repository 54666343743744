import ThemeTopbar from '../theme/containers/Topbar/Topbar'
import styled from 'styled-components'

export const Topbar = styled(ThemeTopbar)<{ url: string }>`
  .isomorphicTopbar {
    display: flex;
    justify-content: space-between;
    background-color: #a7a9ac;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 0 31px 0 265px;
    z-index: 1000;
    transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
  }
`
