import styled from 'styled-components'

interface FlexboxOptions {
  className?: string
  align?: 'center' | 'right' | 'left' | 'stretch'
  flexAlign?: 'start' | 'center' | 'end'
  wrap?: boolean
  justify?: 'space-between' | 'space-around' | 'space-evenly' | 'center' | 'flex-start' | 'flex-end' | 'stretch'
  vertical?: boolean
  children
  [key: string]: any
}
export const Flexbox = styled.div<FlexboxOptions>`
  display: flex;
  ${({ width }) => width ? `width: ${width};` : ''}
  flex-direction: ${({ vertical = false }) => vertical ? 'column' : 'row'};
  align-items: ${({ align = 'center' }) => align};
  justify-content: ${({ justify = 'space-between' }) => justify};
  flex-wrap: ${({ wrap }) => wrap ? 'wrap' : 'nowrap'};
  text-align: ${({ align = 'center' }) => align};
`
