import React, { useState } from 'react'
import store from 'store'

const SIDEBAR_STATUS_KEY = 'evi:sidebar'

export const SidebarContext = React.createContext({
  view: 'MobileView',
  setView: () => undefined,
  collapsed: false,
  openDrawer: false,
  openKeys: [],
  selectedKeys: [],
  changeCurrent: () => undefined,
  toggleCollapsed: () => undefined,
  toggleOpenDrawer: () => undefined
} as any)

export const SidebarProvider = ({ children }) => {
  const [view, setView] = useState('MobileView')
  const [collapsed, setCollapsed] = useState(store.get(SIDEBAR_STATUS_KEY))
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState([])
  const [openKeys, setOpenKeys] = useState([])

  return (
    <SidebarContext.Provider value={{
      view,
      setView: ({ windowWidth }) =>
        setView(
          windowWidth <= 767
            ? 'MobileView'
            : windowWidth <= 1220
              ? 'TabView'
              : 'DesktopView'),
      collapsed,
      openDrawer,
      toggleCollapsed () {
        store.set(SIDEBAR_STATUS_KEY, !collapsed)
        setCollapsed(!collapsed)
      },
      toggleOpenDrawer () {
        setOpenDrawer(!openDrawer)
      },
      openKeys,
      changeOpenKeys: setOpenKeys,
      selectedKeys,
      changeCurrent: setSelectedKeys
    }}>
      {children}
    </SidebarContext.Provider>
  )
}
