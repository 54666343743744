import 'antd/dist/antd.css'
import 'ionicons/css/ionicons.min.css'
import './styles/global.css'
import './styles/evi.css'
import AppRoot from './AppRoot'
import React from 'react'
import ReactDOM from 'react-dom'
import 'moment-timezone'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

ReactDOM.render(<AppRoot />, document.getElementById('root'))
