import React from 'react'
import PublicRoutes from './router'
import { ThemeProvider } from 'styled-components'
import { ConfigProvider, LocaleProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import themes from './settings/themes'
import AppLocale from './languageProvider'
import config, {
  getCurrentLanguage
} from './theme/containers/LanguageSwitcher/config'
import { themeConfig } from './settings'
import AppRootStyle from './styles/AppRootStyle'
import { LogonProvider } from './services/Logon'
import { SidebarProvider } from './services/Sidebar'
import { EviRoutesProvider } from './services/EviRoutes'
import { Providers } from './components/hooks'
import ptBR from 'antd/lib/locale/pt_BR'

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale]

function DashApp () {
  return (
    <ConfigProvider locale={ptBR}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <LogonProvider>
          <Providers>
            <SidebarProvider>
              <EviRoutesProvider>
                <ThemeProvider theme={themes[themeConfig.theme]}>
                  <AppRootStyle>
                    <PublicRoutes />
                  </AppRootStyle>
                </ThemeProvider>
              </EviRoutesProvider>
            </SidebarProvider>
          </Providers>
        </LogonProvider>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default DashApp
export { AppLocale }
