import React, { useContext, useCallback, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Layout } from 'antd'
import queryString from 'query-string'
import Scrollbars from '../../components/utility/customScrollBar'
import Menu from '../../components/uielements/menu'
import IntlMessages from '../../components/utility/intlMessages'
import SidebarWrapper from './sidebar.style'
import Logo from '../../components/utility/logo-small'
import themes from '../../../settings/themes'
import { themeConfig } from '../../../settings'
import { SidebarContext } from '../../../services/Sidebar'
import { DefaultSidebar, SidebarItem, useSidebar } from '../../../components/hooks/useSidebar'
import { useUserOperation } from '../../../components/hooks/useUserOperation'
import QuickSightConfig from '../../../types/QuickSightConfig'
import { EviApi } from '../../../axios-api'
import { alertError } from '../../../functions/alertMessage'

const SubMenu = Menu.SubMenu
const { Sider } = Layout

const stripTrailingSlash = (str) => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1)
  }
  return str
}

interface ColorSettings {
  backgroundColor?: string
  color?: string
}

interface Props {
  url: string
  submenuStyle: ColorSettings
  submenuColor: ColorSettings
  sidebar: SidebarItem[]
  item: SidebarItem
}
const MenuItem = ({ url, item, submenuStyle, submenuColor, sidebar }: Props) => {
  const { key, label, leftIcon, children } = item
  url = stripTrailingSlash(url)

  const customSidebarItem = sidebar.find((customItem) => customItem.key === item.key)
  const availableChildren = children
    .filter((defaultItem) => {
      const customItem = customSidebarItem?.children?.find?.((customItem) => customItem.key === defaultItem.key)

      if (customItem) return customItem.active

      return defaultItem.active
    })

  if (availableChildren) {
    return (
      <SubMenu
        key={key}
        title={
          <span className='isoMenuHolder' style={submenuColor}>
            <i className={leftIcon} />
            <span className='nav-text'>
              <IntlMessages id={label} />
            </span>
          </span>
        }
      >
        {availableChildren
          .map((child) => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            )
          })}
      </SubMenu>
    )
  }
  return (
    <Menu.Item key={key}>
      <Link to={`${url}/${key}`}>
        <span className='isoMenuHolder' style={submenuColor}>
          <i className={leftIcon} />
          <span className='nav-text'>
            <IntlMessages id={label} />
          </span>
        </span>
      </Link>
    </Menu.Item>
  )
}

export default function ({ url }) {
  const { companyHash, operationHash } = useUserOperation()
  const { sidebar } = useSidebar()

  const [quicksightConfigList, setQuicksightConfigList] = useState<QuickSightConfig[]>([])

  const {
    view,
    toggleCollapsed,
    current,
    changeCurrent,
    openDrawer,
    toggleOpenDrawer,
    openKeys,
    changeOpenKeys,
    collapsed: isCollapsed
  } = useContext(SidebarContext)

  const quickSightChildrenFilter = useCallback((menu) => {
    return (menu.children || [])
      .filter((c) => quicksightConfigList
        .find((q) => c.key === q.quicksight_menu_key))
  }, [quicksightConfigList])

  const quickSigthConfigValidate = useCallback((menu) => {
    if (menu.key !== 'management') {
      return true
    }
    if (quicksightConfigList.length > 0) {
      const quickSightList = quickSightChildrenFilter(menu)
      if (quickSightList.length > 0) {
        return true
      }
    }
    return false
  }, [quicksightConfigList, quickSightChildrenFilter])

  const handleClick = useCallback((e) => {
    changeCurrent([e.key])
    if (view === 'MobileView') {
      setTimeout(() => {
        toggleCollapsed()
        toggleOpenDrawer()
      }, 100)
    }
  }, [changeCurrent, toggleCollapsed, toggleOpenDrawer, view])

  const getAncestorKeys = useCallback((key) => {
    const map = {
      sub3: ['sub2']
    }
    return map[key] || []
  }, [])

  const onOpenChange = useCallback((newOpenKeys) => {
    const latestOpenKey = newOpenKeys.find(
      (key) => !(openKeys.indexOf(key) > -1)
    )
    const latestCloseKey = openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    )
    let nextOpenKeys = []
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey)
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey)
    }
    changeOpenKeys(nextOpenKeys)
  }, [changeOpenKeys, getAncestorKeys, openKeys])

  const collapsed = isCollapsed && !openDrawer

  const mode = collapsed ? 'vertical' : 'inline'
  const onMouseEnter = () => {
    if (openDrawer === false) {
      toggleOpenDrawer()
    }
  }
  const onMouseLeave = () => {
    if (openDrawer === true) {
      toggleOpenDrawer()
    }
  }
  const customizedTheme = themes[themeConfig.theme]
  const styling = {
    backgroundColor: customizedTheme.backgroundColor
  }
  const submenuStyle = {
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: customizedTheme.textColor
  }
  const submenuColor = {
    color: customizedTheme.textColor
  }

  useEffect(() => {
    if (!(companyHash && operationHash)) {
      return
    }

    const params = {
      pk_subsidiary_hash: companyHash,
      pk_operation_hash: operationHash
    }

    EviApi.get<QuickSightConfig[]>(`/quicksight?${queryString.stringify(params)}`)
      .then((resp) => {
        if (resp?.data) {
          setQuicksightConfigList(resp.data)
        }
      })
      .catch(alertError)
  }, [companyHash, operationHash])

  return (
    <SidebarWrapper>
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={collapsed}
        width={265}
        className='isomorphicSidebar'
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={styling}
      >
        <Logo />
        <Scrollbars style={{ height: 'calc(100vh - 70px)' }}>
          <Menu
            onClick={handleClick}
            theme='dark'
            className='isoDashboardMenu'
            mode={mode}
            openKeys={collapsed ? [] : openKeys}
            selectedKeys={current}
            onOpenChange={onOpenChange}
          >
            {DefaultSidebar
              .filter((defaultItem) => {
                const customItem = sidebar.find((customItem) => customItem.key === defaultItem.key)

                if (customItem) return customItem.active && quickSigthConfigValidate(customItem)

                return defaultItem.active && quickSigthConfigValidate(defaultItem)
              })
              .map((item) => {
                let childrenList = item.children || []

                if (item.key === 'management') {
                  childrenList = quickSightChildrenFilter(item)
                }

                const menuItem = {
                  ...item,
                  children: childrenList
                }

                return MenuItem({ url, submenuStyle, submenuColor, item: menuItem, sidebar })
              })}
          </Menu>
        </Scrollbars>
      </Sider>
    </SidebarWrapper>
  )
}
