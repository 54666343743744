export enum ApiEndpoint {
  LIST = '/retrieve',
  FILTER = '/retrieve/filter',
  DELETE = '/delete',
  CREATE = '/create',
  UPDATE = '/update',
  KEYWORD_LIST = '/retrieve/list',
  AUTOCOMPLETE_DROPDOWN = '/dropdown',

  PHONE_LIST = '/phone/retrieve',
  PHONE_CREATE = '/phone/create',
  PHONE_UPDATE = '/phone/update',
  PHONE_DELETE = '/phone/delete',

  ADDR_LIST = '/address/retrieve',
  ADDR_CREATE = '/address/create',
  ADDR_UPDATE = '/address/update',
  ADDR_DELETE = '/address/delete',

  EMAIL_LIST = '/mail/retrieve',
  EMAIL_CREATE = '/mail/create',
  EMAIL_UPDATE = '/mail/update',
  EMAIL_DELETE = '/mail/delete',

  LOGBOOK_LIST = '/logbook/retrieve',
  LOGBOOK_CREATE = '/logbook/create',
  LOGBOOK_UPDATE = '/logbook/update',
  LOGBOOK_DELETE = '/logbook/delete',

  CERTIFICATION_LIST = '/certification/retrieve',
  CERTIFICATION_CREATE = '/certification/create',
  CERTIFICATION_UPDATE = '/certification/update',
  CERTIFICATION_DELETE = '/certification/delete',

  USERS_LIST = '/users/retrieve',
  USERS_CREATE = '/users/create',
  USERS_UPDATE = '/users/update',
  USERS_DELETE = '/users/delete',

  OPERATIONS = '/operations',

  OPERATION_DELETE = '/operations/delete',

  OPERATION_EVENTS = '/operation_events',

  OPERATION_JOURNEYS = '/operations/journeys',
  OPERATION_JOURNEYS_DRIVERS = '/operations/journeys/drivers',

  OPERATION_STATUS = '/operations/status',

  TIMELINE_LIST = '/order_service',

  TIMELINE_LIST_V2 = 'v2/order_service/filter',

  BACKOFFICE_LIST = '/backoffice',

  MACRO_LIST = '/relation',

  MACRO_VENDOR = '/vendor',

  MACRO_EVI = '/macros',

  TEMPLATES = '/node_templates',
  TEMPLATES_DELETE = '/node_templates/delete',

  ECONOMIC_GROUP = '/economic_group',

  KPI_AGGREGATION = '/kpi_aggregations',

  VEHICLE_MAINTENANCE = '/v2/vehicle_maintenance',

  USER_OPERATION = '/user_operations',

  USER_OPERATION_COMPANY = '/user_operations_company',

  TIMELINE_DELIVERY = '/timeline/delivery',

  FILE_TEMPLATE = '/file_template',

  NODES = '/nodes',

  LOOKUPS = '/lookups',

  BOUNDARY_GROUPING = '/boundary_grouping',

  QUEUE = '/queue'
}

export const ApiUrl = {
  VEHICLE: process.env.REACT_APP_VEHICLE_API_URL,
  COMPANY: process.env.REACT_APP_COMPANY_API_URL,
  DRIVER: process.env.REACT_APP_DRIVER_API_URL,
  API: process.env.REACT_APP_API_URL
}
