import React, { useMemo } from 'react'
import AntButton from '../theme/components/uielements/button'
import styled from 'styled-components'
import { filterKeys } from '../functions/filterKeys'
import { StandardInputMargin, Margin } from './Margin'
import { Flexbox } from './Flexbox'
import { Col } from 'antd'

export type EviButtonType = 'light' | 'delete' | 'cancel' | 'standard' | 'submit'

interface ButtonProps {
  id: string
  type: EviButtonType
  nomargin?: boolean
  [rest: string]: any
}

const handleTypes = ({ type, eviSubmit, eviCancel, eviDelete }) => ({
  standard: `
    color: white !important;
    background-color: #622d89 !important;
  `,
  light: `
    color: #622d89 !important;
    background-color: transparent !important;
  `,
  submit: `
    color: #622d89 !important;
    background-color: #fee84e !important;
  `,
  delete: `
    color: #fafafa !important;
    background-color: #E90000 !important;
  `,
  cancel: `
    color: #fafafa !important;
    background-color: #aaa !important;
  `

})[type || (eviSubmit ? 'submit' : eviCancel ? 'cancel' : eviDelete ? 'delete' : 'standard')]

const ButtonStyle = styled((props) =>
  <AntButton {...filterKeys(props, { type: false, nomargin: false })} />)<ButtonProps>`
  &, &.ant-btn {
    border: 0 !important;
    padding: 0 20px 0 20px !important;
    line-height: 36px;
    text-transform: uppercase;

    ${handleTypes}

    &[disabled] {
      color: gray;
      background-color: lightgray !important;
    }
  }
`

export function EviButton ({ label, ...props }: ButtonProps) {
  const OptionalCol = useMemo(
    () => props.span
      ? ({ children }) => <Col span={props.span}>{children}</Col>
      : ({ children }) => <>{children}</>,
    [props.span]
  )

  if (props.nomargin) {
    return <ButtonStyle {...props} />
  }

  return (
    <OptionalCol>
      <StandardInputMargin style={props.span && { flexGrow: 1 }}>
        <label style={{ flexGrow: 1 }}>
          <Flexbox vertical align='left'>
            {label &&
              <Margin bottom={2}>
                {label}
              </Margin>
            }
            <ButtonStyle {...props} />
          </Flexbox>
        </label>
      </StandardInputMargin>
    </OptionalCol>

  )
}
