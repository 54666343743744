import React from 'react'
import { UserOperationProvider } from './useUserOperation'
import { SidebarProvider } from './useSidebar'

export function Providers ({ children }) {
  return (
    <UserOperationProvider>
      <SidebarProvider>
        {children}
      </SidebarProvider>
    </UserOperationProvider>
  )
}
