import React, { useCallback } from 'react'
import { EviButton } from '../../../components/EviButton'
import { Flexbox } from '../../../components/Flexbox'
import { InputGroup } from '../../../theme/components/uielements/input'
import { useUserOperation } from '../../../components/hooks/useUserOperation'
import { SelectField } from '../../../components/SelectField'

export function SelectFilialForm ({ onClose }) {
  const {
    selectedCompany,
    companyOptions,
    operationOptions,
    selectedOperation,
    selectCompany,
    selectOperation
  } = useUserOperation()

  const selectParams = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <>
      <InputGroup size='large' style={{ marginBottom: '-10px' }}>

        <SelectField
          span={12}
          label='Selecione a Filial'
          labelColor='#622D88'
          id={'select_filial'}
          value={selectedCompany?.value}
          options={companyOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)}
          onChange={(v) => selectCompany(v)}
        />

        <SelectField
          span={12}
          label='Selecione a Operação'
          labelColor='#622D88'
          id={'select_operation'}
          value={selectedOperation?.value}
          options={operationOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)}
          onChange={(v) => selectOperation(v)}
        />
      </InputGroup>

      <Flexbox justify='flex-end'>
        {selectedCompany?.value
          ? <EviButton
            id='btn-evi-cancel-macro'
            type='cancel'
            className='btn-evi-cancel-macro'
            onClick={onClose}
          >
            CANCELAR
          </EviButton> : ''}
        <EviButton
          id='btn-evi-submit-macro'
          type='submit'
          disabled={!(selectedCompany?.value)}
          onClick={selectParams}
        >
          SELECIONAR
        </EviButton>
      </Flexbox>
    </>
  )
}
