import React from 'react'
import styled from 'styled-components'

const MarginSizes = [
  '0',
  '2px',
  '5px',
  '13px',
  '21px',
  '34px',
  '55px',
  '89px'
]

interface MarginOptions {
  top?: 1 | 2 | 3 | 4 | 5 | 6 | 7
  right?: 1 | 2 | 3 | 4 | 5 | 6 | 7
  bottom?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  left?: 1 | 2 | 3 | 4 | 5 | 6 | 7
}
export const Margin = styled.div<MarginOptions>`
  display: block;
  margin-top: ${({ top }) => top ? MarginSizes[top] : '0'};
  margin-right: ${({ right }) => right ? MarginSizes[right] : '0'};
  margin-bottom: ${({ bottom }) => bottom ? MarginSizes[bottom] : '0'};
  margin-left: ${({ left }) => left ? MarginSizes[left] : '0'};
`

export const StandardInputMargin = ({ children, style = {} }) =>
  <Margin top={2} bottom={3} left={2} right={2} style={style}>
    {children}
  </Margin>
