import React, { useState, useContext, useCallback, useEffect } from 'react'
import Popover from '../../components/uielements/popover'
import IntlMessages from '../../components/utility/intlMessages'
import userpic from '../../../image/user-truck.png'
import TopbarDropdownWrapper from './topbarDropdown.style'
import { LogonContext } from '../../../services/Logon'
import { Modal } from '../../../components/Modal'
import { SelectFilialForm } from './SelectFilialForm'
import { useUserOperation } from '../../../components/hooks/useUserOperation'

export default function () {
  const [isVisible, setIsVisible] = useState(false)
  const { signOut } = useContext(LogonContext)
  const [modalVisible, setModalVisible] = useState(false)
  const { companyHash } = useUserOperation()

  const checkSubsidiarySelected = useCallback(async () => {
    if (!companyHash) {
      setModalVisible(true)
    }
  }, [companyHash])

  useEffect(() => {
    checkSubsidiarySelected()
  }, [checkSubsidiarySelected])

  const content = (
    <TopbarDropdownWrapper className='isoUserDropdown'>
      <a className='isoDropdownLink' onClick={() => setModalVisible(true)}>
        <IntlMessages id='topbar.filial' />
      </a>
      <a className='isoDropdownLink' onClick={signOut} href='# '>
        <IntlMessages id='topbar.logout' />
      </a>
    </TopbarDropdownWrapper>
  )

  return (
    <>
      <Popover
        content={content}
        trigger='click'
        visible={isVisible}
        onVisibleChange={() => setIsVisible(!isVisible)}
        arrowPointAtCenter={true}
        placement='bottomLeft'
      >
        <div className='isoImgWrapper'>
          <img alt='user' src={userpic} />
          <span className='userActivity online' />
        </div>
      </Popover>
      <Modal
        closable={false}
        bind={modalVisible}
        onCancel={() => null}
        title='Escolher Operação'>
        <SelectFilialForm onClose={() => setModalVisible(false)} />
      </Modal>

    </>
  )
}
