import React, { useMemo } from 'react'
import { Col } from 'antd'
import IntlMessages from '../theme/components/utility/intlMessages'
import { fieldIsOk, Validity } from '../functions/FormSchemaValidator'
import Select, { SelectOption as Option } from '../theme/components/uielements/select'
import { Flexbox } from './Flexbox'
import { StandardInputMargin, Margin } from './Margin'
import styled from 'styled-components'

const InfoMessage = styled.span`
  color: #f5222d;
`

export type Option = { value: any; label: string } | string

interface SelectProps {
  isValid?: Validity
  span?: number
  options: Option[]
  onChange?: Function
  id: string
  value?: any
  label?: string
  labelColor?: string
  disabled?: boolean
  errorMessage?: string
}

export function SelectField ({
  label,
  value,
  id,
  onChange,
  isValid,
  options,
  span,
  labelColor,
  disabled = false,
  errorMessage = 'forms.formsWithValidation.requiredField'
}: SelectProps) {
  const OptionalCol = useMemo(
    () => span
      ? ({ children }) => <Col span={span}>{children}</Col>
      : ({ children }) => <>{children}</>,
    [span]
  )

  return (
    <OptionalCol>
      <StandardInputMargin style={{ flexGrow: 1 }}>
        <label style={{ flexGrow: 1 }}>
          <Flexbox vertical align='left'>
            {label &&
              <Margin bottom={2} style={{ color: labelColor }}>
                {label}
              </Margin>
            }
            <Select
              allowClear
              placeholder='Selecione...'
              disabled={disabled}
              onChange={onChange}
              value={value}
              id={id}
              name={id}
              className={
                isValid === 'error'
                  ? 'ant-select-selection-evi' : ''
              }
              style={{ width: '100%' }}
            >
              {options.map((opt) => (
                typeof opt === 'string' ? (
                  <Option key={opt} value={opt}>{opt}</Option>
                ) : (
                  <Option key={opt.value} value={opt.value}>{opt.label}</Option>
                )
              ))}
            </Select>
            {!fieldIsOk(isValid) &&
              <InfoMessage>
                <IntlMessages id={errorMessage}/>
              </InfoMessage>
            }
          </Flexbox>
        </label>
      </StandardInputMargin>
    </OptionalCol>
  )
}
