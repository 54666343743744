import React, { CSSProperties, useCallback, useMemo } from 'react'
import { Col, Input } from 'antd'
import IntlMessages from '../theme/components/utility/intlMessages'
import { fieldIsOk, Validity } from '../functions/FormSchemaValidator'
import styled from 'styled-components'
import { Flexbox } from './Flexbox'
import { Margin, StandardInputMargin } from './Margin'

const EVIInput = styled(Input)<{ error: boolean }>`

.ant-input {
  background-color: #fafafa;
  font-size: 12px;
  border-radius: 4px;
  color: #000;
  line-height: 1.5;
  padding: 0 12px;
  height: 36px;

  &,
  &:focus,
  &:hover {
    outline: none;
    border: #e0e0e0 1px solid;
  }

  ${({ error }) =>
    error
      ? `
    &,
    &:focus,
    &:hover {
      border-color: #f5222d;
    }
  `
      : ''}
  }
`

const InfoMessage = styled.span`
  color: #f5222d;
`

interface TextFieldProps {
  onChange?: Function
  onBlur?: Function
  onPressEnter?: Function
  placeholder?: string
  label?: string
  id: string
  value?: string
  password?: boolean
  isValid?: Validity
  type?: string
  span?: number
  format?: Function
  readOnly?: boolean
  disabled?: boolean
  labelColor?: string
  errorMessage?: string
  labelMarginTop?: string
  labelMarginBottom?: string
  labelStyle?: CSSProperties
  allowClear?: boolean
  max?: string
  min?: string
}

const noop = (value) => value

export function TextField ({
  type,
  placeholder,
  label,
  value = '',
  id,
  format = noop,
  onChange = () => undefined,
  onBlur = () => undefined,
  onPressEnter = () => undefined,
  isValid,
  password = false,
  readOnly = false,
  disabled = false,
  span,
  labelColor,
  errorMessage = 'forms.formsWithValidation.requiredField',
  labelMarginTop = '',
  labelMarginBottom = '',
  labelStyle = {},
  allowClear = false,
  max,
  min
}: TextFieldProps) {
  const OptionalCol = useMemo(
    () =>
      span
        ? ({ children }) => <Col span={span}>{children}</Col>
        : ({ children }) => <>{children}</>,
    [span]
  )

  const onInputChange = useCallback(
    (ev) => onChange(format((ev.nativeEvent.target as HTMLInputElement).value)),
    [onChange, format]
  )

  const onInputBlur = useCallback(
    (ev) => onBlur(format((ev.nativeEvent.target as HTMLInputElement).value)),
    [onBlur, format]
  )

  const onInputEnter = useCallback(
    (ev) => onPressEnter(format((ev.nativeEvent.target as HTMLInputElement).value)),
    [onPressEnter, format]
  )

  return (
    <OptionalCol>
      <StandardInputMargin style={{ flexGrow: 1 }}>
        <label style={{ flexGrow: 1 }}>
          <Flexbox vertical align='left'>
            {label && (
              <Margin
                bottom={2}
                style={{
                  color: labelColor,
                  marginTop: labelMarginTop,
                  marginBottom: labelMarginBottom,
                  ...labelStyle
                }}>
                {label}
              </Margin>
            )}
            <EVIInput
              readOnly={readOnly}
              value={value}
              id={id}
              name={id}
              placeholder={placeholder}
              error={!fieldIsOk(isValid)}
              type={type || (password ? 'password' : 'text')}
              onChange={onInputChange}
              onBlur={onInputBlur}
              onPressEnter={onInputEnter}
              allowClear={allowClear}
              disabled={disabled}
              style={
                readOnly ? { color: 'gray', cursor: 'default' } : undefined
              }
              max={max}
              min={min}
            />
            {!fieldIsOk(isValid) && (
              <InfoMessage>
                <IntlMessages id={errorMessage} />
              </InfoMessage>
            )}
          </Flexbox>
        </label>
      </StandardInputMargin>
    </OptionalCol>
  )
}
