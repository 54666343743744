export function filterKeys (sourceObject, filterObject) {
  if (typeof sourceObject !== 'object' || typeof filterObject !== 'object') {
    throw TypeError('Both arguments must be objects!')
  }

  return Object.entries(sourceObject)
    .filter(([key]) =>
      !(key in filterObject) || filterObject[key])
    .reduce((all, [key, value]) =>
      ({ ...all, [key]: value }), {})
}
