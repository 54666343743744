import React, { useState, useEffect } from 'react'
import store from 'store'
import * as apis from '../axios-api'
import { User } from '../types/User'

const LOGIN_STORE_TOKEN = 'evi:apiToken'
const USER_STORE = 'evi:user'
const USER_EMAIL_STORE = 'evi:useremail'
const ECONOMIC_GROUP_STORE = 'evi:economicgroup'

export const LogonContext = React.createContext({
  isLoggedIn: false,
  currentUser: null,
  signIn: async () => undefined,
  signOut: () => undefined
} as {
  isLoggedIn: boolean
  currentUser: User
  signIn: (email: string, pass: string) => Promise<void>
  signOut: () => void
})

export const LogonProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!store.get(LOGIN_STORE_TOKEN))
  const [currentUser, setCurrentUser] = useState<User>(store.get('evi:current-user'))

  useEffect(() => {
    for (const api of Object.values(apis)) {
      const token = store.get(LOGIN_STORE_TOKEN)

      if (token) {
        api.defaults.headers.Authorization = token
      } else {
        delete api.defaults.headers.Authorization
      }
    }
  }, [isLoggedIn])

  return (
    <LogonContext.Provider value={{
      isLoggedIn,
      currentUser,
      async signIn (email, pass) {
        const form = new FormData()
        form.append('password', pass)
        form.append('email', email)
        const { data: token } = await apis.EviApi.post('/token', form,
          { headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' } })
        store.set(LOGIN_STORE_TOKEN, token)
        store.set(USER_EMAIL_STORE, email)

        const { data: userData } = await apis.EviApi.get('auth', { headers: { Authorization: token } })
        setCurrentUser(userData)
        store.set('evi:current-user', userData)
        store.set(USER_STORE, userData.pk_user_hash)
        store.set(ECONOMIC_GROUP_STORE, userData.pk_economic_group_hash)

        setIsLoggedIn(true)
      },
      signOut () {
        store.remove(LOGIN_STORE_TOKEN)
        setIsLoggedIn(false)
      }
    }}>
      {children}
    </LogonContext.Provider>
  )
}

export function useCurrentUser () {
  return React.useContext(LogonContext).currentUser
}
