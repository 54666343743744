import Message from '../theme/components/feedback/message'
import notification from '../theme/components/feedback/notification'
import styled from 'styled-components'
import React from 'react'
import { AxiosError } from 'axios'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'

const MessageContent = styled.p`
  display: inline-block;
  font-size: 13px;
`

export enum MessageType {
  LOAD = 'loading',
  WARN = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info'
}

export enum MessageID {
  EMPTY_SEARCH = 0,
  EMPTY_PARAM = 1,
  SEARCH_FINISHED = 2,
  SEARCH_FAILED = 3,
  SEARCH_IN_PROGRESS = 4,
  EDIT_SUCCESS = 5,
  EDIT_FAILURE = 6,
  EDIT_IN_PROGRESS = 7,
  COMPANY_DELETE_SUCCESS = 8,
  COMPANY_DELETE_ERROR = 9,
  PHONE_EDIT_IN_PROGRESS = 10,
  PHONE_CREATE = 11,
  PHONE_DELETE_IN_PROGRESS = 14,
  PHONE_DELETE_SUCCESS = 15,
  PHONE_DELETE_FAILURE = 16,
  ADDR_CREATE = 18,
  ADDR_DELETE_IN_PROGRESS = 21,
  ADDR_DELETE_SUCCESS = 22,
  ADDR_DELETE_FAILURE = 23,
  EMAIL_CREATE = 24,
  EMAIL_DELETE_IN_PROGRESS = 25,
  EMAIL_DELETE_SUCCESS = 26,
  EMAIL_DELETE_FAILURE = 27,
  LOGBOOK_CREATE = 28,
  LOGBOOK_DELETE_IN_PROGRESS = 29,
  LOGBOOK_DELETE_SUCCESS = 30,
  LOGBOOK_DELETE_FAILURE = 31,
  CERTIFICATION_CREATE = 32,
  CERTIFICATION_DELETE_IN_PROGRESS = 33,
  CERTIFICATION_DELETE_SUCCESS = 34,
  CERTIFICATION_DELETE_FAILURE = 35,
  CERTIFICATION_EDIT_IN_PROGRESS = 36,
  USER_CREATE = 37,
  USER_DELETE_IN_PROGRESS = 38,
  USER_DELETE_SUCCESS = 39,
  USER_DELETE_FAILURE = 40,
  USER_EDIT_IN_PROGRESS = 41,
  OPERATION_CREATE = 42,
  OPERATION_DELETE_IN_PROGRESS = 43,
  OPERATION_DELETE_SUCCESS = 44,
  OPERATION_DELETE_FAILURE = 45,
  OPERATION_EDIT_IN_PROGRESS = 46,
  PERMISSION_DELETE_IN_PROGRESS = 47,
  PERMISSION_DELETE_SUCCESS = 48,
  PERMISSION_DELETE_FAILURE = 49,
  PERMISSION_EDIT_IN_PROGRESS = 50,
  LOGIN_FAIL = 51,
  DELETE_IN_PROGRESS = 52,
  DELETE_SUCCESS = 53,
  DELETE_FAILURE = 54,
  ROUTE_DELETE_SUCCESS = 55,
  ROUTE_DELETE_FAILURE = 56,
  ROUTE_CREATE_EMPTY = 57,
  MACRO_DELETE_SUCCESS = 58,
  MACRO_DELETE_FAILURE = 59,
  ROUTE_FAILURE = 60,
  SUBSIDIARY_DELETE_IN_PROGRESS = 61,
  SUBSIDIARY_DELETE_SUCCESS = 62,
  SUBSIDIARY_DELETE_FAILURE = 63,
  BACKOFFICE_UPDATE_FAILURE = 64,
  PARAMETER_DELETE_SUCCESS = 65,
  PARAMETER_DELETE_FAILURE = 66,
  ECONOMIC_GROUP_DELETE_SUCCESS = 67,
  ECONOMIC_GROUP_DELETE_FAILURE = 68,
  MAINTENANCE_VEHICLE_DELETE_SUCCESS = 69,
  MAINTENANCE_VEHICLE_DELETE_FAILURE = 70,
  MAINTENANCE_VEHICLE_NOT_FOUND = 71,
  MAINTENANCE_VEHICLE_START_HOUR_NOT_FOUND = 72,
  VEHICLE_DELETE_IN_PROGRESS = 73,
  VEHICLE_DELETE_SUCCESS = 74,
  VEHICLE_DELETE_FAILURE = 75,
  DRIVER_DELETE_IN_PROGRESS = 76,
  DRIVER_DELETE_SUCCESS = 77,
  DRIVER_DELETE_FAILURE = 78,
  NO_END_NODE = 79,
  NO_TEMPLATE = 80,
  BOUNDARY_GROUP_DELETE_IN_PROGRESS = 81,
  BOUNDARY_GROUP_DELETE_SUCCESS = 82,
  BOUNDARY_GROUP_DELETE_FAILURE = 83,
  REQUIRED_FIELD = 84,
  CLIENT_DELETE_SUCCESS = 85,
  CLIENT_DELETE_ERROR = 86,
  BOUNDARY_DELETE_IN_PROGRESS = 87,
  BOUNDARY_DELETE_SUCCESS = 88,
  BOUNDARY_DELETE_FAILURE = 89,
  ROUTE_GENERATE_IN_PROGRESS = 90,
  ROUTE_GENERATE_SUCCESS = 91,
  QRCODE_DRIVER_SELECT_ERROR = 92,
  QRCODE_VEHICLE_SELECT_ERROR = 93,
  DRIVER_LOGGED_OFF = 94,
  EMAIL_SENDING = 95,
  EMAIL_SENT = 96,
  TIME_MAX_FAILURE = 97
}

const AlertText = {
  [MessageID.EMPTY_SEARCH]: 'Nenhum resultado localizado',
  [MessageID.EMPTY_PARAM]: 'Informe algum parâmetro para a pesquisa',
  [MessageID.SEARCH_FINISHED]: 'Pesquisa realizada',
  [MessageID.SEARCH_FAILED]: 'Falha na busca',
  [MessageID.SEARCH_IN_PROGRESS]: 'Pesquisando...',
  [MessageID.EDIT_SUCCESS]: 'Gravação realizada',
  [MessageID.EDIT_FAILURE]: 'Falha ao realizar gravação',
  [MessageID.EDIT_IN_PROGRESS]: 'Gravando...',
  [MessageID.DELETE_IN_PROGRESS]: 'Removendo...',
  [MessageID.DELETE_SUCCESS]: 'Registro excluído',
  [MessageID.DELETE_FAILURE]: 'Falha ao excluir registro',
  [MessageID.CLIENT_DELETE_SUCCESS]: 'Cliente excluído',
  [MessageID.CLIENT_DELETE_ERROR]: 'Falha ao excluir Cliente',
  [MessageID.COMPANY_DELETE_SUCCESS]: 'Filial excluída',
  [MessageID.COMPANY_DELETE_ERROR]: 'Falha ao excluir Filial',
  [MessageID.PHONE_CREATE]: 'Telefone salvo',
  [MessageID.PHONE_DELETE_IN_PROGRESS]: 'Removendo telefone...',
  [MessageID.PHONE_DELETE_SUCCESS]: 'Telefone removido',
  [MessageID.PHONE_DELETE_FAILURE]: 'Falha ao remover telefone',
  [MessageID.EMAIL_CREATE]: 'Email salvo',
  [MessageID.EMAIL_DELETE_IN_PROGRESS]: 'Removendo email...',
  [MessageID.EMAIL_DELETE_SUCCESS]: 'Email removido',
  [MessageID.EMAIL_DELETE_FAILURE]: 'Falha ao remover email',
  [MessageID.EMAIL_SENDING]: 'Enviando email...',
  [MessageID.EMAIL_SENT]: 'Email enviado com sucesso',
  [MessageID.ADDR_CREATE]: 'Endereço salvo',
  [MessageID.ADDR_DELETE_IN_PROGRESS]: 'Removendo endereço...',
  [MessageID.ADDR_DELETE_SUCCESS]: 'Endereço removido',
  [MessageID.ADDR_DELETE_FAILURE]: 'Falha ao remover endereço',
  [MessageID.BOUNDARY_DELETE_IN_PROGRESS]: 'Removendo cerca...',
  [MessageID.BOUNDARY_DELETE_SUCCESS]: 'Cerca removida',
  [MessageID.BOUNDARY_DELETE_FAILURE]: 'Falha ao remover cerca',
  [MessageID.LOGBOOK_CREATE]: 'Diario de bordo salvo...',
  [MessageID.LOGBOOK_DELETE_IN_PROGRESS]: 'Removendo diario de bordo...',
  [MessageID.LOGBOOK_DELETE_SUCCESS]: 'Diario de bordo removido...',
  [MessageID.LOGBOOK_DELETE_FAILURE]: 'Falha ao remover diario de bordo...',
  [MessageID.CERTIFICATION_CREATE]: 'Certificado de bordo salvo...',
  [MessageID.CERTIFICATION_DELETE_IN_PROGRESS]: 'Removendo certificado...',
  [MessageID.CERTIFICATION_DELETE_SUCCESS]: 'Certificado removido',
  [MessageID.CERTIFICATION_DELETE_FAILURE]: 'Falha ao remover certificado',
  [MessageID.CERTIFICATION_EDIT_IN_PROGRESS]: 'Gravando...',
  [MessageID.LOGIN_FAIL]: 'O email e a senha fornecidos não coincidem',
  [MessageID.USER_CREATE]: 'Usuário salvo...',
  [MessageID.USER_DELETE_IN_PROGRESS]: 'Removendo usuário...',
  [MessageID.USER_DELETE_SUCCESS]: 'Usuário removido',
  [MessageID.USER_DELETE_FAILURE]: 'Falha ao remover usuário',
  [MessageID.PARAMETER_DELETE_SUCCESS]: 'Parâmetro removido...',
  [MessageID.PARAMETER_DELETE_FAILURE]: 'Falha ao remover parâmetro...',
  [MessageID.USER_EDIT_IN_PROGRESS]: 'Gravando...',
  [MessageID.OPERATION_CREATE]: 'Operação salva...',
  [MessageID.OPERATION_DELETE_IN_PROGRESS]: 'Removendo operação...',
  [MessageID.OPERATION_DELETE_SUCCESS]: 'Operação removida...',
  [MessageID.OPERATION_DELETE_FAILURE]: 'Falha ao remover operação...',
  [MessageID.OPERATION_EDIT_IN_PROGRESS]: 'Gravando...',
  [MessageID.PERMISSION_DELETE_IN_PROGRESS]: 'Removendo permissão...',
  [MessageID.PERMISSION_DELETE_SUCCESS]: 'Permissão removida...',
  [MessageID.PERMISSION_DELETE_FAILURE]: 'Falha ao remover permissão...',
  [MessageID.PERMISSION_EDIT_IN_PROGRESS]: 'Gravando...',
  [MessageID.ROUTE_DELETE_SUCCESS]: 'Rota removida...',
  [MessageID.ROUTE_DELETE_FAILURE]: 'Falha ao remover rota...',
  [MessageID.ROUTE_CREATE_EMPTY]:
    'Falha ao criar rota. Favor inserir o nome...',
  [MessageID.MACRO_DELETE_SUCCESS]: 'Evento EVI removido...',
  [MessageID.MACRO_DELETE_FAILURE]: 'Falha ao remover evento...',
  [MessageID.ROUTE_FAILURE]: 'Falha ao gerar rota!',
  [MessageID.ROUTE_GENERATE_IN_PROGRESS]: 'Gerando rota...',
  [MessageID.ROUTE_GENERATE_SUCCESS]: 'Rota gerada com sucesso!',
  [MessageID.SUBSIDIARY_DELETE_IN_PROGRESS]: 'Removendo filial...',
  [MessageID.SUBSIDIARY_DELETE_SUCCESS]: 'Filial removida...',
  [MessageID.BACKOFFICE_UPDATE_FAILURE]:
    'Por favor, corrija os erros indicados...',
  [MessageID.ECONOMIC_GROUP_DELETE_SUCCESS]: 'Grupo Econômico removido...',
  [MessageID.ECONOMIC_GROUP_DELETE_FAILURE]:
    'Falha ao remover Grupo Econômico...',
  [MessageID.MAINTENANCE_VEHICLE_DELETE_SUCCESS]:
    'Manutenção do Veículo removida...',
  [MessageID.MAINTENANCE_VEHICLE_DELETE_FAILURE]:
    'Falha ao remover Manutenção do Veículo...',
  [MessageID.MAINTENANCE_VEHICLE_NOT_FOUND]:
    'Veículo não encontrado, verifique a placa...',
  [MessageID.MAINTENANCE_VEHICLE_START_HOUR_NOT_FOUND]:
    'Hora de início da manuntenção não informada...',
  [MessageID.VEHICLE_DELETE_IN_PROGRESS]: 'Removendo veículo...',
  [MessageID.VEHICLE_DELETE_SUCCESS]: 'Veículo removido...',
  [MessageID.VEHICLE_DELETE_FAILURE]: 'Falha ao remover veículo...',
  [MessageID.DRIVER_DELETE_IN_PROGRESS]: 'Removendo colaborador...',
  [MessageID.DRIVER_DELETE_SUCCESS]: 'Colaborador removido...',
  [MessageID.DRIVER_DELETE_FAILURE]: 'Falha ao remover colaborador...',
  [MessageID.NO_END_NODE]: 'Último ponto da rota não é um "Fim de Rota"',
  [MessageID.NO_TEMPLATE]: 'Não foi encontrado template para esta operação',
  [MessageID.BOUNDARY_GROUP_DELETE_IN_PROGRESS]: 'Removendo grupo de cercas...',
  [MessageID.BOUNDARY_GROUP_DELETE_SUCCESS]: 'Grupo de cercas removido...',
  [MessageID.BOUNDARY_GROUP_DELETE_FAILURE]: 'Falha ao deletar grupo de cercas',
  [MessageID.REQUIRED_FIELD]: 'Preencha todos os campos obrigatórios',
  [MessageID.QRCODE_DRIVER_SELECT_ERROR]: 'O colaborador selecionado não possui operação vinculada!',
  [MessageID.QRCODE_VEHICLE_SELECT_ERROR]: 'O veículo selecionado não possui operação vinculada!',
  [MessageID.TIME_MAX_FAILURE]: 'O tempo mínimo não pode ser superior ao tempo máximo!'
}

interface Message {
  text: string
  title?: string
  duration?: number | null
}

export function alertMessage (type: MessageType, data: MessageID | Message) {
  let title = ''
  let msg = ''
  let duration
  if (typeof data === 'number') {
    msg = AlertText[data]
  } else {
    title = data.title || ''
    msg = data.text
    duration = data.duration
  }

  Message.destroy()
  if (type === MessageType.LOAD) {
    Message[type]({
      content: <MessageContent>{msg}</MessageContent>
    })
  } else {
    const color = '#ffffff'
    let backgroundColor = '#539bbd'
    let icon = <InfoIcon style={{ fontSize: 30 }}/>

    switch (type) {
    case MessageType.SUCCESS:
      duration = duration === undefined ? 5 : duration
      backgroundColor = '#55bd53'
      icon = <CheckCircleOutlineIcon style={{ fontSize: 30 }}/>
      break
    case MessageType.WARN:
      duration = duration === undefined ? 10 : duration
      backgroundColor = '#bd8d53'
      icon = <WarningIcon style={{ fontSize: 30 }}/>
      break
    case MessageType.ERROR:
      duration = duration === undefined ? null : duration
      backgroundColor = '#bd5353'
      icon = <ErrorOutlineIcon style={{ fontSize: 30 }}/>
      break
    default:
      duration = duration === undefined ? 15 : duration
      break
    }
    notification[type]({
      message: <div style={{ color: color }}>{title || msg}</div>,
      description: title ? msg : '',
      duration: duration,
      style: { backgroundColor: backgroundColor, color: color },
      icon: icon
    })
  }
}

export function alertError (err: AxiosError) {
  try {
    const { error_type, field_name } = err.response.data[0]
    if (error_type === 'duplicated_field') {
      alertMessage(MessageType.ERROR, {
        title: `${field_name} já cadastrado`,
        text: `Este ${field_name} já foi utilizado.`
      })
    } else if (error_type === 'required_field') {
      alertMessage(MessageType.ERROR, { text: `Campo ${field_name} obrigatório` })
    } else if (error_type === 'invalid_type') {
      alertMessage(MessageType.ERROR, { text: `Formato do campo ${field_name} inválido` })
    } else if (error_type === 'enum_fail') {
      alertMessage(MessageType.ERROR, { text: `Valor do campo ${field_name} diferente do esperado` })
    } else if (error_type === 'invalid_date') {
      alertMessage(MessageType.ERROR, { text: `Formato de data incorreto no campo ${field_name}` })
    } else if (error_type === 'invalid_doc') {
      alertMessage(MessageType.ERROR, { text: `Formato de documento incorreto no campo ${field_name}` })
    } else if (error_type === 'not_alphanumeric') {
      alertMessage(MessageType.ERROR, { text: `Campo ${field_name} deve conter apenas caracteres e numerais` })
    } else {
      const errorDesc = err.response.data[0].error_description
      alertMessage(MessageType.ERROR, { text: errorDesc })
    }
  } catch (error) {
    if (error instanceof TypeError) {
      const error_type = err?.response?.data?.message?.[0]?.error_type
      const field_name = err?.response?.data?.message?.[0]?.field_name
      if (error_type === 'duplicated_field') {
        alertMessage(MessageType.ERROR, {
          title: `${field_name} já cadastrado`,
          text: `Este ${field_name} já foi utilizado.`
        })
      } else if (error_type === 'required_field') {
        alertMessage(MessageType.ERROR, { text: `Campo ${field_name} obrigatório` })
      } else if (error_type === 'invalid_type') {
        alertMessage(MessageType.ERROR, { text: `Formato do campo ${field_name} inválido` })
      } else if (error_type === 'enum_fail') {
        alertMessage(MessageType.ERROR, { text: `Valor do campo ${field_name} diferente do esperado` })
      } else if (error_type === 'invalid_date') {
        alertMessage(MessageType.ERROR, { text: `Formato de data incorreto no campo ${field_name}` })
      } else if (error_type === 'invalid_doc') {
        alertMessage(MessageType.ERROR, { text: `Formato de documento incorreto no campo ${field_name}` })
      } else if (error_type === 'not_alphanumeric') {
        alertMessage(MessageType.ERROR, { text: `Campo ${field_name} deve conter apenas caracteres e numerais` })
      } else if (error_type === 'macro_registered_as_subsequent') {
        alertMessage(
          MessageType.ERROR,
          { text: 'Essa macro não pode ser excluída porque está configurada como subsequente de outra' }
        )
      } else if (error_type === 'macro_registered_in_driver_logbook') {
        alertMessage(MessageType.ERROR, { text: 'Essa macro não pode ser excluída porque existem apontamentos associados a ela' })
      } else {
        // eslint-disable-next-line
        console.error(err)
        // eslint-disable-next-line
        console.error(error)
        alertMessage(MessageType.ERROR, { text: 'Servidor Indisponível' })
      }
    } else {
      // eslint-disable-next-line
      console.error(err)
      // eslint-disable-next-line
      console.error(error)
      alertMessage(MessageType.ERROR, { text: 'Erro inesperado' })
    }
  }
}
