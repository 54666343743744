import React from 'react'
import Scrollbar from 'react-smooth-scrollbar'

interface ScrollbarProps {
  id?: string
  className?: string
  style?: any
  children
}

export default ({ id, style, children, className }: ScrollbarProps) => (
  <Scrollbar
    id={id}
    className={className}
    style={style}
    continuousScrolling={true}
  >
    {children}
  </Scrollbar>
)
