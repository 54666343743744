import React, { useState } from 'react'
import { Route } from '../types/Route'

export const EviRoutesContext = React.createContext({
  searchString: '',
  searchExtId: '',
  search: [],
  setSearchString: () => undefined,
  setSearchExtId: () => undefined,
  setSearch: () => undefined
} as {
  search: Route[]
  setSearch: Function
  searchString: string
  setSearchString: Function
  searchExtId: string
  setSearchExtId: Function
})

export const EviRoutesProvider = ({ children }) => {
  const [search, setSearch] = useState([])
  const [searchString, setSearchString] = useState()
  const [searchExtId, setSearchExtId] = useState()

  return (
    <EviRoutesContext.Provider value={{ search, setSearch, searchString, setSearchString, searchExtId, setSearchExtId }}>
      {children}
    </EviRoutesContext.Provider>
  )
}
