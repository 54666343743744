import React, { useContext } from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'

import App from './pages/App'
import asyncComponent from './helpers/AsyncFunc'
import { LogonContext } from './services/Logon'

const AuthRedirectRoute = '/'

interface Route {
  path: string
  component: Function
  isExact?: boolean
  isRestricted?: boolean
}

const Routes: Route[] = [
  {
    path: '/',
    isExact: true,
    component: asyncComponent(() => import('./pages/SignIn'))
  },
  {
    path: '/signin',
    isExact: true,
    component: asyncComponent(() => import('./pages/SignIn'))
  },
  {
    path: '/register',
    isExact: true,
    component: asyncComponent(() => import('./pages/Register'))
  },
  {
    path: '/dashboard/component',
    isExact: true,
    isRestricted: true,
    component: asyncComponent(() => import('./pages/DashboardComponentNew'))
  },
  {
    path: '/main',
    isRestricted: true,
    component: App
  },
  {
    path: '/health',
    component: asyncComponent(() => import('./pages/Health'))
  }
]

interface RestrictedRouteProps {
  component: (any) => any
  path: string
}
const RestrictedRoute = ({ component: Component, ...props }: RestrictedRouteProps) => {
  const { isLoggedIn } = useContext(LogonContext)

  return (
    <Route
      {...props}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: AuthRedirectRoute,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default () => {
  return (
    <BrowserRouter>
      <div>
        {Routes.map((route) => {
          const Component = route.isRestricted ? RestrictedRoute : Route

          return <Component
            key={route.path}
            path={route.path}
            exact={route.isExact}
            component={route.component}
          />
        })}
      </div>
    </BrowserRouter>
  )
}
