import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'

import { EviApi } from '../../axios-api'
import { useCurrentUser } from '../../services/Logon'

import { alertError } from '../../functions/alertMessage'
import { useUserOperation } from './useUserOperation'
import QuickSightConfig from '../../types/QuickSightConfig'

export interface SidebarItem {
  key: string
  label: string
  active: boolean
  leftIcon?: string
  children?: SidebarItem[]
  withoutDashboard?: boolean
}

export const DefaultManagementItens: SidebarItem = {
  key: 'management',
  label: 'sidebar.management',
  active: true,
  leftIcon: 'ion-stats-bars',
  children: [
    {
      key: 'management_shippings',
      label: 'sidebar.management_shippings',
      active: true
    },
    {
      key: 'management_customs_time',
      label: 'sidebar.management_customs_time',
      active: true
    },
    {
      key: 'management_alerts',
      label: 'sidebar.management_alerts',
      active: true
    },
    {
      key: 'management_dashboard',
      label: 'sidebar.management_dashboard',
      active: true
    },
    {
      key: 'management_kpis',
      label: 'sidebar.management_kpis',
      active: true
    }
  ]
}

export const DefaultSidebar: SidebarItem[] = [
  {
    key: 'towercontrol',
    label: 'sidebar.dashboard',
    active: true,
    leftIcon: 'ion-stats-bars',
    children: [
      {
        key: 'dashboard',
        active: true,
        label: 'sidebar.torre.dashboard'
      },
      {
        key: 'indicadores',
        active: true,
        label: 'sidebar.torre.indicadores'
      },
      {
        key: 'entregas',
        active: true,
        label: 'sidebar.torre.entregas'
      },
      {
        key: 'alertas',
        active: true,
        label: 'sidebar.torre.alertas'
      },
      {
        key: 'fretes',
        active: true,
        label: 'sidebar.torre.freights'
      }
      // {
      //   key: 'graficos',
      //   label: 'sidebar.torre.graficos'
      // }
    ]
  },
  {
    key: 'empresa',
    label: 'sidebar.empresa',
    active: true,
    leftIcon: 'ion-briefcase',
    children: [
      {
        key: 'filiais',
        active: true,
        label: 'sidebar.filial'
      },
      {
        key: 'clientes',
        label: 'sidebar.cliente',
        active: true
      },
      {
        key: 'veiculos',
        label: 'sidebar.veiculos',
        active: true
      },
      {
        key: 'colaborador',
        label: 'sidebar.colaboradores',
        active: true
      },
      {
        key: 'vehicles-qr-code',
        label: 'sidebar.veiculos_qrcode_generator',
        active: true
      },
      {
        key: 'drivers-qr-code',
        label: 'sidebar.drivers_qrcode_generator',
        active: true
      }
    ]
  },
  {
    key: 'operation',
    label: 'sidebar.operation',
    active: true,
    leftIcon: 'ion-android-checkbox-outline',
    children: [
      {
        key: 'operations',
        label: 'sidebar.operations',
        active: true
      },
      {
        key: 'rota',
        label: 'sidebar.rota',
        active: true
      },
      {
        key: 'driver_scaling',
        label: 'sidebar.driver_scaling',
        active: true
      },
      {
        key: 'scaling',
        label: 'sidebar.scaling',
        active: true
      }
      // {
      //   key: 'manutencaoveiculo',
      //   label: 'sidebar.manutencaoveiculo',
      //   active: true
      // }
    ]
  },
  {
    key: 'backoffice',
    label: 'sidebar.backoffice',
    active: true,
    leftIcon: 'ion-filing',
    children: [
      {
        key: 'programming',
        label: 'sidebar.programming',
        active: true
      },
      {
        key: 'logbook',
        label: 'sidebar.logbook',
        active: true
      },
      {
        key: 'telemetry',
        label: 'sidebar.telemetry',
        active: true
      },
      {
        key: 'timeline',
        label: 'sidebar.parametros_timeline',
        active: true
      }
    ]
  },
  DefaultManagementItens,
  // {
  //   key: 'mensagens',
  //   label: 'sidebar.mensagens',
  //   leftIcon: 'ion-chatboxes'
  // },
  {
    key: 'admin',
    label: 'sidebar.admin',
    active: true,
    leftIcon: 'ion-gear-b',
    children: [
      {
        key: 'macros',
        label: 'sidebar.macros',
        active: true
      },
      {
        key: 'lookups',
        label: 'sidebar.lookups',
        active: true
      },
      {
        key: 'grupoeconomico',
        label: 'sidebar.grupoeconomico',
        active: true,
        leftIcon: 'ion-clipboard'
      },
      {
        key: 'parametros_gerais',
        label: 'sidebar.parametros_gerais',
        active: true
      },
      {
        key: 'usuario',
        label: 'sidebar.usuario',
        active: true
      },
      {
        key: 'arvore_valores',
        active: true,
        label: 'sidebar.torre.value_tree'
      }
    ]
  }
]

interface SidebarContextProps {
  sidebar: SidebarItem[]
  setSidebar: React.Dispatch<React.SetStateAction<SidebarItem[]>>
  permissions: string[]
  permissionsLoading: boolean
  quicksightConfigLoading: boolean
}
const SidebarContext = React.createContext<SidebarContextProps>({
  sidebar: DefaultSidebar,
  setSidebar: () => undefined,
  permissions: [],
  permissionsLoading: true,
  quicksightConfigLoading: true
})

export function SidebarProvider ({ children }) {
  const currentUser = useCurrentUser()
  const { companyHash, operationHash } = useUserOperation()

  const [sidebar, setSidebar] = useState<SidebarItem[]>([])
  const [permissionsLoading, setPermissionsLoading] = useState(true)
  const [quicksightConfigLoading, setQuicksightConfigLoading] = useState(true)
  const [quicksightConfigList, setQuicksightConfigList] = useState<QuickSightConfig[]>([])

  const quickSightChildrenFilter = useCallback((menu) => {
    return (menu.children || [])
      .filter((c) => quicksightConfigList
        .find((q) => c.key === q.quicksight_menu_key))
  }, [quicksightConfigList])

  const quickSigthConfigValidate = useCallback((menu) => {
    if (menu.key !== 'management') {
      return true
    }
    if (quicksightConfigList.length > 0) {
      const quickSightList = quickSightChildrenFilter(menu)
      if (quickSightList.length > 0) {
        return true
      }
    }
    return false
  }, [quicksightConfigList, quickSightChildrenFilter])

  useEffect(() => {
    if (!(companyHash && operationHash)) {
      setQuicksightConfigLoading(false)
      return
    }

    setQuicksightConfigLoading(true)
    if (EviApi?.defaults?.headers?.Authorization) {
      const params = {
        pk_subsidiary_hash: companyHash,
        pk_operation_hash: operationHash
      }

      EviApi.get<QuickSightConfig[]>(`/quicksight?${queryString.stringify(params)}`)
        .then((resp) => {
          if (resp?.data) {
            setQuicksightConfigList(resp.data)
          }
        })
        .catch(alertError)
        .finally(() => setQuicksightConfigLoading(false))
    }
  }, [companyHash, operationHash, currentUser])

  useEffect(() => {
    if (currentUser && EviApi?.defaults?.headers?.Authorization) {
      setPermissionsLoading(true)
      EviApi.get('/user-menu?pk_user_hash=' + currentUser.pk_user_hash)
        .then((r) => {
          if (r?.data) {
            return r.data[0]
          }
        })
        .then((menu) => {
          const userSidebar = (menu?.items ? [...(menu?.items || []), DefaultManagementItens] : DefaultSidebar)
            .filter((m) => quickSigthConfigValidate(m))
          setSidebar(userSidebar)
          setPermissionsLoading(false)
        })
    }
  }, [currentUser, quickSigthConfigValidate])

  const permissions = useMemo(() => sidebar
    .filter((item) => quickSigthConfigValidate(item))
    .flatMap((item) => {
      let childrenList = item.children || []

      if (item.key === 'management') {
        childrenList = quickSightChildrenFilter(item)
      }

      return [item, ...childrenList]
    })
    .filter((item) => item.active)
    .map((i) => i.key)
  , [sidebar, quickSigthConfigValidate, quickSightChildrenFilter])

  return (
    <SidebarContext.Provider value={{ sidebar, setSidebar, permissions, permissionsLoading, quicksightConfigLoading }}>
      {children}
    </SidebarContext.Provider>
  )
}

export function useSidebar () {
  return useContext(SidebarContext)
}

export function RouteGuard ({ requiredPermission }) {
  const { permissions, permissionsLoading, quicksightConfigLoading } = useSidebar()

  if (permissionsLoading || quicksightConfigLoading || permissions.find((perm) => perm === requiredPermission)) {
    return <>{false}</>
  } else {
    return <Redirect to='/main/dashboard' />
  }
}
