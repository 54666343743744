import React, { useContext } from 'react'
import { Layout } from 'antd'
import { Debounce } from 'react-throttle'
import WindowResizeListener from 'react-window-size-listener'
import { ThemeProvider } from 'styled-components'
import Sidebar from '../theme/containers/Sidebar/Sidebar'
import { Topbar } from '../components/Topbar'
import AppRouter from '../components/AppRouter'
import { siteConfig, themeConfig } from '../settings'
import themes from '../settings/themes'
import AppHolder from '../styles/AppStyle'
import { SidebarContext } from '../services/Sidebar'
import { Providers } from '../components/hooks'
import useVersion from '../components/hooks/useVersion'

interface AppProps {
  match: any
  height: number
  toggleAll: Function
  url: string
}

export default function ({ height, match: { url } }: AppProps) {
  const { setView } = useContext(SidebarContext)
  const appHeight = window.innerHeight
  const version = useVersion()

  return (
    <ThemeProvider theme={themes[themeConfig.theme]}>
      <Providers>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time='1000' handler='onResize'>
              <WindowResizeListener onResize={setView} />
            </Debounce>
            <Topbar url={url} />
            <Layout style={{
              flexDirection: 'row',
              overflowX: 'hidden'
            }}>
              <Sidebar url={url} />
              <Layout
                className='isoContentMainLayout'
                style={{ height }}
              >
                <Layout.Content
                  className='isomorphicContent'
                  style={{
                    padding: '70px 0 0',
                    flexShrink: 0,
                    background: '#f1f3f6',
                    position: 'relative'
                  }}
                >
                  <AppRouter url={url} style={{ height: '100%' }}/>
                </Layout.Content>
                <Layout.Footer
                  style={{
                    background: '#ffffff',
                    textAlign: 'center',
                    borderTop: '1px solid #ededed'
                  }}
                >
                  {siteConfig.footerText + ' ' + version}
                </Layout.Footer>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </Providers>
    </ThemeProvider>
  )
}
