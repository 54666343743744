/* eslint-disable */

import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../image/evi.svg'

export default () => {
  return (
    <div className='isoLogoWrapper'>
      <div>
        <h3>
          <Link to='/main/dashboard'>
            <img src={Logo} alt='EVI' />
          </Link>
        </h3>
      </div>
    </div>
  )
}
