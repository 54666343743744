import React, { CSSProperties } from 'react'
import { Route } from 'react-router-dom'
import asyncComponent from '../helpers/AsyncFunc'
import RouteDescription from '../types/RouteDescription'

const routes: RouteDescription[] = [
  {
    path: 'clientes',
    component: asyncComponent(() => import('../pages/Clients'))
  },
  {
    path: 'filiais',
    component: asyncComponent(() => import('../pages/Subsidiaries'))
  },
  {
    path: 'veiculos',
    component: asyncComponent(() => import('../pages/VehiclesNew'))
  },
  {
    path: 'vehicles-qr-code',
    component: asyncComponent(() => import('../pages/VechilesQrCodeGenerator'))
  },
  {
    path: 'drivers-qr-code',
    component: asyncComponent(() => import('../pages/DriversQrCodeGenerator'))
  },
  // {
  //   path: 'manutencaoveiculo',
  //   component: asyncComponent(() => import('../pages/VehiclesMaintenance'))
  // },
  {
    path: 'colaborador',
    component: asyncComponent(() => import('../pages/Driver'))
  },
  {
    path: 'usuario',
    component: asyncComponent(() => import('../pages/Users'))
  },
  {
    path: 'gestaooperacao',
    component: asyncComponent(() => import('../pages/ManagementOperation'))
  },
  {
    path: 'grupoeconomico',
    component: asyncComponent(() => import('../pages/EconomicGroup'))
  },
  {
    exact: true,
    path: 'rota',
    component: asyncComponent(() => import('../pages/Routes'))
  },
  {
    exact: true,
    path: 'operations',
    component: asyncComponent(() => import('../pages/Operations'))
  },
  {
    path: 'timeline',
    component: asyncComponent(() => import('../pages/Timeline'))
  },
  // {
  //   path: 'programming',
  //   component: asyncComponent(() => import('../pages/BackofficeProgramming'))
  // },
  {
    path: 'sequence',
    component: asyncComponent(() => import('../pages/BackofficeSequence'))
  },
  {
    path: 'driver_scaling',
    component: asyncComponent(() => import('../pages/DriverScaling'))
  },
  {
    path: 'scaling',
    component: asyncComponent(() => import('../pages/BackofficeScaling'))
  },
  {
    path: 'macros',
    component: asyncComponent(() => import('../pages/Macros'))
  },
  {
    path: 'lookups',
    component: asyncComponent(() => import('../pages/Lookups'))
  },
  {
    path: 'boundaries',
    component: asyncComponent(() => import('../pages/BoundaryGrouping'))
  },
  {
    path: 'rota/:routeID',
    component: asyncComponent(() => import('../pages/EditRoute'))
  },
  {
    path: 'dashboard',
    component: asyncComponent(() => import('../pages/DashboardNew2'))
  },
  {
    path: 'indicadores',
    component: asyncComponent(() => import('../pages/Kpi'))
  },
  {
    path: 'entregas',
    component: asyncComponent(() => import('../pages/DashboardAppDeliveries'))
  },
  {
    path: 'alertas',
    component: asyncComponent(() => import('../pages/DashboardAppAlerts'))
  },
  {
    path: 'graficos',
    component: asyncComponent(() => import('../pages/DashboardAppCharts'))
  },
  {
    path: 'parametros_gerais',
    component: asyncComponent(() => import('../pages/Parameters'))
  },
  {
    path: 'console',
    component: asyncComponent(() => import('../pages/Console'))
  },
  {
    path: 'programming',
    component: asyncComponent(() => import('../pages/Backoffice'))
  },
  {
    path: 'fretes',
    component: asyncComponent(() => import('../pages/FleetControl'))
  },
  {
    path: 'arvore_valores',
    component: asyncComponent(() => import('../pages/ValueTree'))
  },
  {
    path: 'quick_sight_test',
    component: asyncComponent(() => import('../pages/QuickSightTest'))
  },
  {
    path: 'management_shippings',
    component: asyncComponent(() => import('../pages/ManagementShippings'))
  },
  {
    path: 'management_customs_time',
    component: asyncComponent(() => import('../pages/ManagementCustomsTime'))
  },
  {
    path: 'management_alerts',
    component: asyncComponent(() => import('../pages/ManagementAlerts'))
  },
  {
    path: 'management_dashboard',
    component: asyncComponent(() => import('../pages/ManagementDashboard'))
  },
  {
    path: 'management_kpis',
    component: asyncComponent(() => import('../pages/ManagementKPIs'))
  },
  {
    path: 'telemetry',
    component: asyncComponent(() => import('../pages/Telemetry'))
  },
  {
    path: 'logbook',
    component: asyncComponent(() => import('../pages/logbook'))
  }
]

interface AppRouterProps {
  url: string
  style?: CSSProperties
}
function AppRouter ({ url, style }: AppRouterProps) {
  return (
    <div style={style}>
      {routes.map((singleRoute) => {
        const { path, exact, ...otherProps } = singleRoute
        return (
          <Route
            exact={exact}
            key={path}
            path={`${url}/${path}`}
            {...otherProps}
          />
        )
      })}
    </div>
  )
}
export default AppRouter
