import { useState, useEffect } from 'react'
import { EviApi } from '../../axios-api'
import { alertError } from '../../functions/alertMessage'

export default function useVersion () {
  const [version, setVersion] = useState<string>()

  useEffect(() => {
    EviApi.get<string>('/version')
      .then((r) => {
        setVersion(r.data)
      })
      .catch(alertError)
  }, [])

  return version
}
